import revive_payload_client_vuR6D6dbJD from "/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.9.1_eslint@8.57.1_ioredis@5.4.1_magicast@_ctgtptlfl54vor3af3lj4oo2aq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_TC7rbqhqel from "/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.9.1_eslint@8.57.1_ioredis@5.4.1_magicast@_ctgtptlfl54vor3af3lj4oo2aq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_w1rRBg4rjy from "/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.9.1_eslint@8.57.1_ioredis@5.4.1_magicast@_ctgtptlfl54vor3af3lj4oo2aq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_DrQ6zrYB28 from "/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.27.3_vite@5.4.11_@types+node@22.9.1_terser@5._5kgan4cud4jbkht775nl4u6hqq/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_VqvYXwrPYY from "/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.9.1_eslint@8.57.1_ioredis@5.4.1_magicast@_ctgtptlfl54vor3af3lj4oo2aq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_SXkIzASnf7 from "/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.9.1_eslint@8.57.1_ioredis@5.4.1_magicast@_ctgtptlfl54vor3af3lj4oo2aq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_aq7yH376f8 from "/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.9.1_eslint@8.57.1_ioredis@5.4.1_magicast@_ctgtptlfl54vor3af3lj4oo2aq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_y5bo40yKJJ from "/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.9.1_eslint@8.57.1_ioredis@5.4.1_magicast@_ctgtptlfl54vor3af3lj4oo2aq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_ZyY9I15rrU from "/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.27.3_typescript@5.6.3_vue@3.5.13_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/.nuxt/components.plugin.mjs";
import prefetch_client_Z2lRd5yWhc from "/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.9.1_eslint@8.57.1_ioredis@5.4.1_magicast@_ctgtptlfl54vor3af3lj4oo2aq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_OHU0vggoM9 from "/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import fontawesome_RkkdgLScnI from "/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@vesp+nuxt-fontawesome@1.1.0_magicast@0.3.5_rollup@4.27.3/node_modules/@vesp/nuxt-fontawesome/dist/runtime/plugins/fontawesome.js";
import nuxt_plugin_cUO4IUoQQc from "/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@4.27.3/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import switch_locale_path_ssr_wWc2Y9J196 from "/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.27.3_vue@3.5.13_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_R2TfD9V8Yl from "/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.27.3_vue@3.5.13_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_w016SqEANS from "/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@storyblok+nuxt@6.2.0/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import plugin_EvKkdykEKd from "/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.27.3_typesc_fqtbdvpdqqpt3eysolivid3cg4/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import base64_2hii42QBJd from "/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/plugins/base64.ts";
export default [
  revive_payload_client_vuR6D6dbJD,
  unhead_TC7rbqhqel,
  router_w1rRBg4rjy,
  _0_siteConfig_DrQ6zrYB28,
  payload_client_VqvYXwrPYY,
  navigation_repaint_client_SXkIzASnf7,
  check_outdated_build_client_aq7yH376f8,
  chunk_reload_client_y5bo40yKJJ,
  plugin_vue3_ZyY9I15rrU,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Z2lRd5yWhc,
  plugin_OHU0vggoM9,
  fontawesome_RkkdgLScnI,
  nuxt_plugin_cUO4IUoQQc,
  switch_locale_path_ssr_wWc2Y9J196,
  i18n_R2TfD9V8Yl,
  plugin_w016SqEANS,
  plugin_EvKkdykEKd,
  base64_2hii42QBJd
]