import { default as _91_46_46_46slug_936JIL6stlFoMeta } from "/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[...slug].vue?macro=true";
import { default as _91product_id_9328l66pfbBxMeta } from "/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[country]/[product]/[product_id].vue?macro=true";
import { default as _91location_93Ld3F1at35iMeta } from "/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[location].vue?macro=true";
import { default as component_45stubFzPzOhW9XXMeta } from "/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.9.1_eslint@8.57.1_ioredis@5.4.1_magicast@_ctgtptlfl54vor3af3lj4oo2aq/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubFzPzOhW9XX } from "/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.9.1_eslint@8.57.1_ioredis@5.4.1_magicast@_ctgtptlfl54vor3af3lj4oo2aq/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___us",
    path: "/us/:slug(.*)*",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[...slug].vue")
  },
  {
    name: "slug___uk",
    path: "/uk/:slug(.*)*",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[...slug].vue")
  },
  {
    name: "slug___au",
    path: "/au/:slug(.*)*",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[...slug].vue")
  },
  {
    name: "slug___ca",
    path: "/ca/:slug(.*)*",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[...slug].vue")
  },
  {
    name: "slug___ie",
    path: "/ie/:slug(.*)*",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[...slug].vue")
  },
  {
    name: "slug___nz",
    path: "/nz/:slug(.*)*",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[...slug].vue")
  },
  {
    name: "slug___za",
    path: "/za/:slug(.*)*",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[...slug].vue")
  },
  {
    name: "slug___es",
    path: "/es/:slug(.*)*",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[...slug].vue")
  },
  {
    name: "slug___cl",
    path: "/cl/:slug(.*)*",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[...slug].vue")
  },
  {
    name: "slug___ar",
    path: "/ar/:slug(.*)*",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[...slug].vue")
  },
  {
    name: "slug___mx",
    path: "/mx/:slug(.*)*",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[...slug].vue")
  },
  {
    name: "slug___fr",
    path: "/fr/:slug(.*)*",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[...slug].vue")
  },
  {
    name: "slug___ca-fr",
    path: "/ca-fr/:slug(.*)*",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[...slug].vue")
  },
  {
    name: "slug___be",
    path: "/be/:slug(.*)*",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[...slug].vue")
  },
  {
    name: "slug___de",
    path: "/de/:slug(.*)*",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[...slug].vue")
  },
  {
    name: "slug___ch",
    path: "/ch/:slug(.*)*",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[...slug].vue")
  },
  {
    name: "slug___ch-fr",
    path: "/ch-fr/:slug(.*)*",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[...slug].vue")
  },
  {
    name: "slug___at",
    path: "/at/:slug(.*)*",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[...slug].vue")
  },
  {
    name: "slug___pt",
    path: "/pt/:slug(.*)*",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[...slug].vue")
  },
  {
    name: "slug___br",
    path: "/br/:slug(.*)*",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[...slug].vue")
  },
  {
    name: "slug___it",
    path: "/it/:slug(.*)*",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[...slug].vue")
  },
  {
    name: "slug___nl",
    path: "/nl/:slug(.*)*",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[...slug].vue")
  },
  {
    name: "slug___be-nl",
    path: "/be-nl/:slug(.*)*",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[...slug].vue")
  },
  {
    name: "slug___pl",
    path: "/pl/:slug(.*)*",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[...slug].vue")
  },
  {
    name: "slug___jp",
    path: "/jp/:slug(.*)*",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[...slug].vue")
  },
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[...slug].vue")
  },
  {
    name: "type-country-product-product_id___us",
    path: "/us/:type()/:country()/:product()/:product_id()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[country]/[product]/[product_id].vue")
  },
  {
    name: "type-country-product-product_id___uk",
    path: "/uk/:type()/:country()/:product()/:product_id()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[country]/[product]/[product_id].vue")
  },
  {
    name: "type-country-product-product_id___au",
    path: "/au/:type()/:country()/:product()/:product_id()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[country]/[product]/[product_id].vue")
  },
  {
    name: "type-country-product-product_id___ca",
    path: "/ca/:type()/:country()/:product()/:product_id()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[country]/[product]/[product_id].vue")
  },
  {
    name: "type-country-product-product_id___ie",
    path: "/ie/:type()/:country()/:product()/:product_id()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[country]/[product]/[product_id].vue")
  },
  {
    name: "type-country-product-product_id___nz",
    path: "/nz/:type()/:country()/:product()/:product_id()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[country]/[product]/[product_id].vue")
  },
  {
    name: "type-country-product-product_id___za",
    path: "/za/:type()/:country()/:product()/:product_id()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[country]/[product]/[product_id].vue")
  },
  {
    name: "type-country-product-product_id___es",
    path: "/es/:type()/:country()/:product()/:product_id()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[country]/[product]/[product_id].vue")
  },
  {
    name: "type-country-product-product_id___cl",
    path: "/cl/:type()/:country()/:product()/:product_id()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[country]/[product]/[product_id].vue")
  },
  {
    name: "type-country-product-product_id___ar",
    path: "/ar/:type()/:country()/:product()/:product_id()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[country]/[product]/[product_id].vue")
  },
  {
    name: "type-country-product-product_id___mx",
    path: "/mx/:type()/:country()/:product()/:product_id()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[country]/[product]/[product_id].vue")
  },
  {
    name: "type-country-product-product_id___fr",
    path: "/fr/:type()/:country()/:product()/:product_id()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[country]/[product]/[product_id].vue")
  },
  {
    name: "type-country-product-product_id___ca-fr",
    path: "/ca-fr/:type()/:country()/:product()/:product_id()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[country]/[product]/[product_id].vue")
  },
  {
    name: "type-country-product-product_id___be",
    path: "/be/:type()/:country()/:product()/:product_id()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[country]/[product]/[product_id].vue")
  },
  {
    name: "type-country-product-product_id___de",
    path: "/de/:type()/:country()/:product()/:product_id()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[country]/[product]/[product_id].vue")
  },
  {
    name: "type-country-product-product_id___ch",
    path: "/ch/:type()/:country()/:product()/:product_id()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[country]/[product]/[product_id].vue")
  },
  {
    name: "type-country-product-product_id___ch-fr",
    path: "/ch-fr/:type()/:country()/:product()/:product_id()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[country]/[product]/[product_id].vue")
  },
  {
    name: "type-country-product-product_id___at",
    path: "/at/:type()/:country()/:product()/:product_id()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[country]/[product]/[product_id].vue")
  },
  {
    name: "type-country-product-product_id___pt",
    path: "/pt/:type()/:country()/:product()/:product_id()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[country]/[product]/[product_id].vue")
  },
  {
    name: "type-country-product-product_id___br",
    path: "/br/:type()/:country()/:product()/:product_id()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[country]/[product]/[product_id].vue")
  },
  {
    name: "type-country-product-product_id___it",
    path: "/it/:type()/:country()/:product()/:product_id()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[country]/[product]/[product_id].vue")
  },
  {
    name: "type-country-product-product_id___nl",
    path: "/nl/:type()/:country()/:product()/:product_id()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[country]/[product]/[product_id].vue")
  },
  {
    name: "type-country-product-product_id___be-nl",
    path: "/be-nl/:type()/:country()/:product()/:product_id()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[country]/[product]/[product_id].vue")
  },
  {
    name: "type-country-product-product_id___pl",
    path: "/pl/:type()/:country()/:product()/:product_id()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[country]/[product]/[product_id].vue")
  },
  {
    name: "type-country-product-product_id___jp",
    path: "/jp/:type()/:country()/:product()/:product_id()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[country]/[product]/[product_id].vue")
  },
  {
    name: "type-country-product-product_id___en",
    path: "/:type()/:country()/:product()/:product_id()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[country]/[product]/[product_id].vue")
  },
  {
    name: "type-location___us",
    path: "/us/:type()/:location()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[location].vue")
  },
  {
    name: "type-location___uk",
    path: "/uk/:type()/:location()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[location].vue")
  },
  {
    name: "type-location___au",
    path: "/au/:type()/:location()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[location].vue")
  },
  {
    name: "type-location___ca",
    path: "/ca/:type()/:location()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[location].vue")
  },
  {
    name: "type-location___ie",
    path: "/ie/:type()/:location()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[location].vue")
  },
  {
    name: "type-location___nz",
    path: "/nz/:type()/:location()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[location].vue")
  },
  {
    name: "type-location___za",
    path: "/za/:type()/:location()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[location].vue")
  },
  {
    name: "type-location___es",
    path: "/es/:type()/:location()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[location].vue")
  },
  {
    name: "type-location___cl",
    path: "/cl/:type()/:location()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[location].vue")
  },
  {
    name: "type-location___ar",
    path: "/ar/:type()/:location()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[location].vue")
  },
  {
    name: "type-location___mx",
    path: "/mx/:type()/:location()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[location].vue")
  },
  {
    name: "type-location___fr",
    path: "/fr/:type()/:location()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[location].vue")
  },
  {
    name: "type-location___ca-fr",
    path: "/ca-fr/:type()/:location()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[location].vue")
  },
  {
    name: "type-location___be",
    path: "/be/:type()/:location()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[location].vue")
  },
  {
    name: "type-location___de",
    path: "/de/:type()/:location()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[location].vue")
  },
  {
    name: "type-location___ch",
    path: "/ch/:type()/:location()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[location].vue")
  },
  {
    name: "type-location___ch-fr",
    path: "/ch-fr/:type()/:location()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[location].vue")
  },
  {
    name: "type-location___at",
    path: "/at/:type()/:location()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[location].vue")
  },
  {
    name: "type-location___pt",
    path: "/pt/:type()/:location()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[location].vue")
  },
  {
    name: "type-location___br",
    path: "/br/:type()/:location()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[location].vue")
  },
  {
    name: "type-location___it",
    path: "/it/:type()/:location()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[location].vue")
  },
  {
    name: "type-location___nl",
    path: "/nl/:type()/:location()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[location].vue")
  },
  {
    name: "type-location___be-nl",
    path: "/be-nl/:type()/:location()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[location].vue")
  },
  {
    name: "type-location___pl",
    path: "/pl/:type()/:location()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[location].vue")
  },
  {
    name: "type-location___jp",
    path: "/jp/:type()/:location()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[location].vue")
  },
  {
    name: "type-location___en",
    path: "/:type()/:location()",
    component: () => import("/codebuild/output/src1168586469/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/pages/[type]/[location].vue")
  },
  {
    name: component_45stubFzPzOhW9XXMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubFzPzOhW9XX
  }
]